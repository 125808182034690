import { createChatBotMessage } from "react-chatbot-kit";
import LinkList from '../chatbot/LinkList/LinkList'
import LearningOptions from "./LearningOptions/LearningOptions";

const config = {
  botName: "Amazon Bot",
  initialMessages: [
    createChatBotMessage("Hi, I'm here to help. What do you want to learn?", {
      widget: "learningOptions",
    }),
  ],
  
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} />,
    },
    {
      widgetName: "javascriptLinks",
      widgetFunc: (props) => {
        return (
          <LinkList
            {...props}
            options={[
             
              // {
              //   text: "Debit card atm",
              //  handler: props.actionProvider.atmRedirect,
              //  id:8,
              // },
            ]}
          />
        );
      },
    },
    {
      widgetName: "atmLinks",
      widgetFunc: (props) => {
        return (
          <LinkList
            {...props}
            options={[
              {
                text: "Apply For atm",
                //handler: props.actionProvider.atmRedirect,
               //   id:7,
              },
              {
                text: "Debit card atm",
               // handler: props.actionProvider.atmRedirect,
               // id:8,
              },
            ]}
          />
        );
      },
    },
  ],
};

export default config;
