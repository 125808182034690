import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table1';
import api from '../Api';
import Header from '../../components/Header';
import { toast } from 'react-toastify';

function RefundRequest() {
    const [refundRequest ,setRefundRequest]=useState([])

    useEffect(() => {
        GetAllRefundRequest(); 
      }, []);

    function GetAllRefundRequest()
    {
        debugger
        api.get("https://ottwebappnode.csdevhub.com/api/Orders/getpendingrefund").then((d)=>{
            setRefundRequest(d.data);
            console.log(d.data);

        })
    }
    function handlerefund(orderHeaderId) {
        debugger;
        api
          .post(`https://ottwebappnode.csdevhub.com/api/ShoppingCart/processrefund?orderId=${orderHeaderId}`)
          .then((response) => {
            toast.success(response.data);
            GetAllRefundRequest();
          })
          .catch((error) => {
            toast.error(error.response.data);
          });
      }
    const columns = useMemo(
        () => [
          {
            Header: "OrderId",
            accessor: "id",
          },
          {
            Header: "orderHeaderId",
            accessor: "orderHeaderId",
          },
        
          {
            Header: "Product Title",
            accessor: "product.title",
          },
          {
            Header: "OrderStatus",
            accessor: "orderHeader.orderStatus",
          },
          {
            Header: "PaymentStatus",
            accessor: "orderHeader.paymentStatus",
          },
        //   {
        //     Header: "Image",
        //     accessor: "imageUrl",
        //     Cell: ({ cell: { value } }) => (
        //       <img
        //         className="card-img-top"
        //         src={`data:image/jpeg;base64,${value}`}
        //         height="100"
        //         alt="Product Image"
        //       />
        //     ),
        //   },
        
          {
            Header: "Action",
            Cell: ({ row: { original } }) => (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => handlerefund(original.orderHeaderId)}
                  >
                    Refund Payment
                  </button>
               
              </>
            ),
          },
        ],
        []
      );
    
  return (
    <div><Header/>
    
    <div className="table table-borderd text-center">
    <h1  className='text-center'>Refund Request</h1>
      <Table columns={columns} paging={true} data={refundRequest} />
    </div>
  </div>
  )
}

export default RefundRequest