import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../navigation/Router";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";


function OtpLogin() {
  const [form, setForm] = useState({
    Otp: "",
  });
  
  const loginError = () => toast.error('Wrong OTP', {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  const loginMessage = () => toast.success('OTP Sended Successfully', {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  useEffect(()=>{
loginMessage();
  },[])
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
         `https://ottwebappnode.csdevhub.com/api/Authenticate/otpverification?userId=${localStorage.getItem("id")}&code=` + form.Otp,
        //`https://ottwebappnode.csdevhub.com/api/Authenticate/otpverification?userId=${localStorage.getItem("id")}&code=` + form.Otp,

      );
      toast.success("Login Successfully done !");
      const { token, refreshToken, role, name,id } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("role", role);
      localStorage.setItem("Name", name);
      localStorage.setItem("applicationUserId", id);
      navigate("/home");
    } catch (error) {
      loginError();
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <section>
        <div className="sign_container">
          <div className="sign_header">
            <img
              src="https://logo-logos.com/2016/12/Amazon_logo.png"
              alt="signupimg"
            />
          </div>
          <div className="sign_form">
            <form method="POST">
              {/* <h1>Verify email</h1> */}

              <div className="form_data">
                <label htmlFor="otp">OTP</label>
                <input
                  type="number"
                  name="Otp"                  
                  onChange={handleChange}
                  placeholder="Enter Otp"
                  id="otp"
                />
              </div>
              {/* <div className="form_data">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                id="password"
                placeholder="At least 6 characters"
              />
            </div> */}
              <button
                // type="submit"
                className="signin_btn"
                onClick={handleSubmit}
                // onClick={() => navigate(ROUTES.OtpLogin.name)}
              >
                Continue
              </button>
            </form>
            <ToastContainer />
          </div>
          <div className="create_accountinfo">
            <p>New to Amazon?</p>
            <button>
              <Link to={ROUTES.signup.name}>Create your Account</Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OtpLogin;
