import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify';
import api from '../Api';
import Header from '../../components/Header';
import Table from '../../components/Table1';

function ExchangeRequest() { 
    const [exchnagePending ,setExchangePending]=useState([])
    const [orderId, setorderid] = useState(null);
   
  
      useEffect(() => {
        getAllExchangePending(); 
        }, []);
  
      function getAllExchangePending()
      {
          debugger
          api.get("https://ottwebappnode.csdevhub.com/api/Orders/getexchangepending").then((d)=>{
              setExchangePending(d.data);
  
              console.log(d.data);
  
          })
      }
      function handleExchange(orderHeaderId, productId) {
        debugger;
        api.post(
          `https://ottwebappnode.csdevhub.com/api/ShoppingCart/exchangeProcess?orderDetailId=${orderHeaderId}&newProductId=${productId}`,
          {},
          {
            headers: {
              accept: "*/*",
            },
          }
        )
          .then((response) => {
            toast.success(response.data);
            getAllExchangePending();
          })
          .catch((error) => {
            toast.error(error.response.data);
          });
      }
      const columns = useMemo(
          () => [
            {
              Header: "OrderId",
              accessor: "id",
            },
            {
              Header: "orderHeaderId",
              accessor: "orderHeaderId",
            },
          
            {
              Header: "Product Title",
              accessor: "product.title",
            },
            {
              Header: "OrderStatus",
              accessor: "orderHeader.orderStatus",
            },
            {
              Header: "PaymentStatus",
              accessor: "orderHeader.paymentStatus",
            },
          {
              Header: "Action",
              Cell: ({ row: { original } }) => (
                  <>
                    <button
                      className="btn btn-success"
                      onClick={() => handleExchange(original.id,original.productId)}
                    >
                      Approve Exchange
                    </button>
                 
                </>
              ),
            },
          ],
          []
        );
        return (
            <div><Header/>
              <div className="table table-borderd text-center">
                    <h1  className='text-center'>Exchange Request</h1>
                <Table columns={columns} paging={true} data={exchnagePending} />
              </div>
            </div>
          )
      }

    
    
export default ExchangeRequest