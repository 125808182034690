import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ROUTES from './Router'
import SignUp from '../containers/signup/SignUp'
import AllMobile from '../containers/category/allMobile/AllMobile'
import Product from '../containers/admin/product/Product'
import Login from '../containers/login/Login'

import Home from '../containers/home/Home'
import Customer from '../containers/admin/customer/Customer'
import Contact from '../containers/contact/Contact'
import About from '../containers/about/About'
import Register from '../containers/Register'
import AllHomeKitchen from '../containers/category/allHome_Kitchen/AllHome_Kitchen'
import AllElectronic from '../containers/category/allElectronic/AllElectronic'
import AllProducts from '../containers/category/allProducts/AllProducts'

import { SearchResult } from '../containers/home/searchResult/SearchResult'
import CompareProducts from '../containers/compareProducts/CompareProducts'
import Cart from '../containers/cart/Cart'
import ProceedBuy from '../containers/cart/ProceedBuy'
import Orderconfirm from '../containers/cart/Orderconfirm'
import MyOrder from '../containers/cart/myOrders/myOrder'
import OtpLogin from '../containers/login/OtpLogin'
import AdminDashboard from '../containers/admin/adminDashboard/AdminDashboard'
import ProductReview from '../containers/productReview/ProductReview'
import OverallSalesSummary from '../containers/admin/adminDashboard/OverallSalesSummary'
import Account from '../containers/home/accountDetail/Account'
import ProductDetail from '../containers/home/productDetail/ProductDetail'
import ProductCard from '../containers/cart/myOrders/ProductCard'
import ExchangeRequest from '../containers/admin/ExchangeRequest'

import PendingOrders from '../containers/admin/PendingOrders'
import RefundRequest from '../containers/admin/RefundRequest'

function Navigation() {
  return (
    <div>
        <BrowserRouter>
        <Routes>
        <Route path="/register" Component={Register} />
            {/* <Route path={ROUTES.register.name}element={ROUTES.register.component}/> */}
            <Route path="/about" Component={About} />
            {/* <Route path={ROUTES.about.name}element={ROUTES.about.component}/> */}
            <Route path="/contact" Component={Contact} />
            {/* <Route path={ROUTES.contact.name}element={ROUTES.contact.component}/> */}
            <Route path="/customer" Component={Customer} />
            {/* <Route path={ROUTES.customer.name}element={ROUTES.customer.component}/> */}
            <Route path="/" Component={Home} />
            <Route path="/home" Component={Home} />

            {/* <Route path={ROUTES.home.name}element={ROUTES.home.component}/> */}
            <Route path="/login" Component={Login} />
            {/* <Route path={ROUTES.login.name}element={ROUTES.login.component}/> */}
            {/* <Route path={ROUTES.signup.name}element={ROUTES.signup.component}/> */}
            <Route path="/signup" Component={SignUp} />
             <Route path="/product" Component={Product} /> 
             {/* <Route path={ROUTES.product.name}element={ROUTES.product.component}/>  */}
            <Route path="/allMobile" Component={AllMobile} />
            {/* <Route path={ROUTES.allMobile.name}element={ROUTES.allMobile.component}/> */}
            <Route path="/allHomeKitchen" Component={AllHomeKitchen} />
            {/* <Route path={ROUTES.allHomeKitchen.name}element={ROUTES.allHomeKitchen.component}/> */}
            <Route path="/allElectronic" Component={AllElectronic} />
            {/* <Route path={ROUTES.allElectronic.name}element={ROUTES.allElectronic.component}/> */}
            <Route path="/allProducts" Component={AllProducts} />
            {/* <Route path={ROUTES.allProducts.name}element={ROUTES.allProducts.component}/> */}
            <Route path="/detail" Component={ProductDetail} />
            {/* <Route path={ROUTES.detail.name}element={ROUTES.detail.component}/> */}
            <Route path="/SearchResult" Component={SearchResult} />
            {/* <Route path={ROUTES.SearchResult.name}element={ROUTES.SearchResult.component}/> */}
            <Route path="/compareProducts" Component={CompareProducts} />
            {/* <Route path={ROUTES.compareProducts.name}element={ROUTES.compareProducts.component}/> */}
            <Route path="/cart" Component={Cart} />
            {/* <Route path={ROUTES.Cart.name}element={ROUTES.Cart.component}/> */}
            <Route path="/ProceedBuy" Component={ProceedBuy} />
            {/* <Route path={ROUTES.ProceedBuy.name}element={ROUTES.ProceedBuy.component}/> */}
            <Route path="/Orderconfirm" Component={Orderconfirm} />
            {/* <Route path={ROUTES.Orderconfirm.name}element={ROUTES.Orderconfirm.component}/> */}
            <Route path="/MyOrder" Component={MyOrder} />
            {/* <Route path={ROUTES.MyOrder.name}element={ROUTES.MyOrder.component}/> */}
            <Route path="/OtpLogin" Component={OtpLogin} />
            {/* <Route path={ROUTES.OtpLogin.name}element={ROUTES.OtpLogin.component}/> */}
            <Route path="/AdminDashboard" Component={AdminDashboard} />
            {/* <Route path={ROUTES.AdminDashboard.name}element={ROUTES.AdminDashboard.component}/> */}
            <Route path="/ProductReview" Component={ProductReview} />
            {/* <Route path={ROUTES.ProductReview.name}element={ROUTES.ProductReview.component}/> */}
            <Route path="/OverallSalesSummary" Component={OverallSalesSummary} />
            {/* <Route path={ROUTES.OverallSalesSummary.name}element={ROUTES.OverallSalesSummary.component}/> */}
            <Route path="/AccountDetail" Component={Account} />
            {/* <Route path={ROUTES.AccountDetail.name}element={ROUTES.AccountDetail.component}/> */}
            <Route path="/ProductCard" Component={ProductCard} />
             <Route path="/ExchangeRequest" Component={ExchangeRequest} /> 
            <Route path="/PendingOrders" Component={PendingOrders} />
            <Route path="/RefundRequest" Component={RefundRequest} />
        </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Navigation