// import logo from './logo.svg';
// import './App.css';
// import Navigation from './navigation/Navigation';
// import Header from './components/Header';
// import HomeContainer from './services/homeContainer';
// import { Counter } from './features/counter/Counter';

// function App() {
//   return (
//     <div className="App"> 
//       <Counter/>
//       {/* <Navigation/> */}
//     </div>
//   );
// }

// export default App;
import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { Navigate } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import alanBtn from "@alan-ai/alan-sdk-web";
import config from './containers/chatbot/config';
import MessageParser from './containers/chatbot/MessageParser';
import Chatbot from 'react-chatbot-kit';
import ActionProvider from './containers/chatbot/ActionProvider';
import 'react-chatbot-kit/build/main.css'
import Home from './containers/home/Home';

function App() {
  const [chatbotOpen, setChatbotOpen] = useState(false);

  const toggleChatbot = () => {
    setChatbotOpen(!chatbotOpen);
  };

  const closeChatbot = () => {
    setChatbotOpen(false);
  };

  useEffect(() => {
    // alanBtn({
    //   key: 'ece1998aeee137fa678b6497c44c93982e956eca572e1d8b807a3e2338fdd0dc/stage',
    //   onCommand: (commandData) => {
    //     if (commandData.command === 'go:back') {
    //       // Call the client code that will react to the received command
    //     }
    //   }
    // });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
      
      </header>
      <div style={{ maxWidth: "500px", backgroundColor: "black" }}>
        <div className="chatbot-icon" onClick={toggleChatbot}>
          <img
            // src="https://cdn.dribbble.com/users/37530/screenshots/2937858/drib_blink_bot.gif"
            src="https://miro.medium.com/max/1200/1*9I6EIL5NG20A8se5afVmOg.gif"
            alt="Chatbot Icon"
          />
        </div>
        <div className="containers">
          {chatbotOpen && (
            <>
              <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
              />
              <button className='btn btn-info' onClick={closeChatbot}>Close Chatbot</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
