// import React, { useEffect, useState } from "react";
import "./Header.css";
import images from "./images/amazon.png";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, Routes, useNavigate } from "react-router-dom";
import ROUTES from "../navigation/Router";
import Home from "../containers/home/Home";
import { SearchBar } from "../containers/home/searchBar/SearchBar";
import { SearchResultsList } from "../containers/home/searchResultsList/SearchResultsList";
import Search from "../containers/home/Search/Search";
import { useSelector } from "react-redux";
import { FaWeight } from "react-icons/fa";
import { Sidebar } from "semantic-ui-react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FaBars, FaTimes } from 'react-icons/fa';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";

function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState({ role: null, name: null });
  // const count = useSelector((state) => state.counter.value)

  useEffect(() => {
    let role = localStorage.getItem("role");
    let name = localStorage.getItem("Name");
    setUser({ name: name, role: role });
    console.log(results);
  }, []);

  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div >
        <div className="header">
         {user.role ?  <Link 
          to={"/AccountDetail"}>
            <div className="row">
              <div className="col-5">
                <div className="flex-shrink-0">
                  <MDBCardImage
                    style={{ width: '70px' }}
                    className="img-fluid rounded-circle border border-dark border-3"
                    src="https://tse1.mm.bing.net/th/id/OIP.I2qn7t4JEpypQhW46al9hQHaHa?rs=1&pid=ImgDetMain"
                    alt='Generic placeholder image'
                    fluid />
                </div>
              </div>
              <div className="col-4 ">
                {user?.role ? <h6 className="text-white"> Hello{user.name}</h6> : <h5 className="text-white">Signin</h5>}                
              </div>
            </div>
          </Link> :  <Link 
          to={"/login"}>
            <div className="row">
              <div className="col-5">
                <div className="flex-shrink-0">
                  <MDBCardImage
                    style={{ width: '70px' }}
                    className="img-fluid rounded-circle border border-dark border-3"
                    src="https://tse1.mm.bing.net/th/id/OIP.I2qn7t4JEpypQhW46al9hQHaHa?rs=1&pid=ImgDetMain"
                    alt='Generic placeholder image'
                    fluid />
                </div>
              </div>
              <div className="col-4 ">
                 <h5 className="text-white">Signin</h5>            
              </div>
            </div>
          </Link> }
         
        </div>
      </div>
      {/* <List>
        {['Mobiles', 'Electronic', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
     
    </Box>
  );

  useSelector((state) => {
    return state.counter.value;
  });

 
  const [results, setResults] = useState([]);

  return (
    <div>
      <div className="header">
        <Link to={"/home"}>
          <img className="header__logo" src={images} />
        </Link>

        <div className="header__search">
          <div className="rounded-textbox">
            <SearchBar setResults={setResults} />
          </div>
        </div>

        <div className="header__nav">
          {user?.role ? (
            <div className="header__option">
              <span className="header__optionLineOne" >Hello, {user.name}</span>
              <Link
                className="header__optionLineTwo"
                onClick={() => {
                  localStorage.clear();
                }}
                to={"/login"}
              >
                Sign Out
              </Link>
            </div>
          ) : (
            <div className="header__option">
              <span className="header__optionLineOne">Hello Guest</span>
              <Link className="header__optionLineTwo" to={"/login"}>
                Sign In
              </Link>
            </div>
          )}

          <div className="header__option">
            <span onClick={() => {
              navigate("/MyOrder")
            }} className="header__optionLineOne">Returns</span>
            <span onClick={() => {
              navigate("/MyOrder")
            }} className="header__optionLineTwo">& Orders</span>

          </div>

          <div className="header__optionBasket">
            <Link className="basket" to={"/Cart"}>
              <ShoppingCartIcon />
            </Link>
            {localStorage.getItem("cartLength")}
      
            <span className="header__basketCount"></span>
          </div>
        </div>
      </div>

      {/* <div class="header1">
        <div class="container container-header">
          <div className="header__nav">
            <div className="header__option">
              <span className="header__optionLineTwo">All Orders</span>
            </div>
            <div className="header__option">
              <span className="header__optionLineTwo">Mobile</span>
            </div>
            <div className="header__option">
              <span className="header__optionLineTwo">Home & Kitchen</span>
            </div>
            <div className="header__option">
              <span className="header__optionLineTwo">Electronic</span>
            </div>
          </div>
        </div>
      </div> */}

      <nav class="nav">
        <div class="container container-nav">
          <ul className="ul">
            <li class="border3"> 
              {['left'].map((anchor) => (
                <React.Fragment key={anchor}>

                  <FaBars onClick={toggleDrawer(anchor, true)}>{anchor} </FaBars>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </li>
            <li class="border-white">
              <Link to={"/allProducts"}>All</Link>
            </li>
            <li class="border-white">
              <Link to={"/allMobile"}>Mobile</Link>
            </li>
            <li class="border-white">
              <Link to={"/allElectronic"}>Electronic</Link>
            </li>
            <li class="border-white">
              <Link to={"/allHomeKitchen"}>Home & Kitchen</Link>
            </li>
            {user?.role == "Admin" ? (
              <li class="border-white">
                <Link to={"/product"}>Product Dashboard</Link>
              </li>
              // <li class="border-white">
              //   <Link to={ROUTES.allHomeKitchen.name}>Dashboard</Link>             
              // </li>
            ) : ""}
            {user?.role == "Admin" ? (
     <>
    <li class="border-white">
      <Link to={"/AdminDashboard"}>Dashboard</Link>
    </li>
    <li class="border-white">
      <Link to={"/PendingOrders"}>Pending Orders</Link>
    </li>
    <li class="border-white">
      <Link to={"/RefundRequest"}>Refund Requests</Link>
    </li>
    <li class="border-white">
      <Link to={"/ExchangeRequest"}>Exchange Requests </Link>
    </li>
    
   
    
    
  </>
) : (
  ""
)}


          </ul>
        </div>
      </nav>
      <div>
        {<SearchResultsList results={results} />}
      </div>
    </div>
  );
}
export default Header;