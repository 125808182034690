// import React, { useEffect, useState } from 'react'
// import './ProductReview.css'
// import Header from '../../components/Header';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import Rating from 'react-simple-star-rating';

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

// function ProductReview() {

//   const [text, setText] = useState('');
//   const [selected, setSelected] = useState(5);
//   const queryParams = useQuery();
//   const [review,setReview] = useState([]);
//   const [form, setForm] = useState({
//     ApplicationUserId: "",
//     ProductId: 0,
//     Comment: "",
//     Rating: 0
//   })



//   function getReviews()
//   {
//     axios.get(`https://ottwebappnode.csdevhub.com/api/ProductReview/GetReviewByProductId?productId=${queryParams.get("id")}`).then((d) => {
//       console.log(d.data)
//       setReview(d.data)
//     })  
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     try {
//       const response = await axios.post("https://ottwebappnode.csdevhub.com/api/ProductReview", form);
//       alert("Data saved");
//       clearform();
//     } catch (error) {
//       alert("Something went wrong");
//     }
//   };
//   const handleTextChange = event => {
//     setText(event.target.value);
//   };

//   const handleChange = e => {
//     setSelected(+e.currentTarget.value);
//   };

//   function clearform()
//   {
//     setForm({
//       Comment: "",
//       Rating: 4
//     })
//   }

//   useEffect(()=>{
//   getReviews();
//   console.log(review);
//   },[])

//   useEffect(() => {
//     setForm({
//       ApplicationUserId: localStorage.getItem("id"),
//       ProductId: queryParams.get("id"),
//       Comment: text,
//       Rating: selected
//     })
   
//     console.log(form)
//     console.log(text);
//   }, [text, selected])
//   function clearform()
//   {setForm({})
//   }

//   return (
//     <div>
//       <ul className="rating">
//         <li>
//           <input
//             type="radio"
//             id="num1"
//             name="rating"
//             value="1"
//             onChange={handleChange}
//             checked={selected === 1}
//           />
//           <label htmlFor="num1">1</label>
//         </li>
//         <li>
//           <input
//             type="radio"
//             id="num2"
//             name="rating"
//             value = "2"
//             onChange={handleChange}
//             checked={selected === 2}
//           />
//           <label htmlFor="num2">2</label>
//         </li>
//         <li>
//           <input
//             type="radio"
//             id="num3"
//             name="rating"
//             value="3"
//             onChange={handleChange}
//             checked={selected === 3}
//           />
//           <label htmlFor="num3">3</label>
//         </li>
//         <li>
//           <input
//             type="radio"
//             id="num4"
//             name="rating"
//             value="4"
//             onChange={handleChange}
//             checked={selected === 4}
//           />
//           <label htmlFor="num4">4</label>
//         </li>
//         <li>
//           <input
//             type="radio"
//             id="num5"
//             name="rating"
//             value="5"
//             onChange={handleChange}
//             checked={selected === 5}
//           />
//           <label htmlFor="num5">5</label>
//         </li>
//       </ul>
     
//         <div className="input-group">
//           <input
//             onChange={handleTextChange}
//             type="text"
//             placeholder="Write a review"
//             value={text}
//           />
//           <button onClick={handleSubmit} >Send</button>
//         </div>
//         <br/>
//         {review.map((item)=>(
//          <div className='row'> 
//          <h3 className='col-3'>Rating : {item.rating}</h3>
//          <h4>comment : {item.comment}</h4>
//            </div>
//         ))}    
       
       
//     </div>
//   )
// }

// export default ProductReview
import React, { useEffect, useState } from 'react';
import './ProductReview.css';
import Header from '../../components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating';
import { Item } from 'semantic-ui-react';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function ProductReview() {
  const [text, setText] = useState('');
  const [selected, setSelected] = useState(5);
  const queryParams = useQuery();
  const [review, setReview] = useState([]);

  const [form, setForm] = useState({
    ApplicationUserId: "",
    ProductId: 0,
    Comment: "",
    Rating: 0
  });
  useEffect(() => {
    getReviews();
  
    console.log(review);
  }, []);
  const navigate = useNavigate();
  function getReviews() {
    axios.get(`https://ottwebappnode.csdevhub.com/api/ProductReview/GetReviewByProductId?productId=${queryParams.get("id")}`).then((d) => {
      console.log(d.data);
      setReview(d.data);
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await axios.post("https://ottwebappnode.csdevhub.com/api/ProductReview", form);
      alert("Data saved");
      clearform();
      getReviews();
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const handleTextChange = event => {
    setText(event.target.value);
  };

  const handleChange = (rating) => {
    setSelected(rating);
  };

  function clearform() {
    setForm({
      Comment: "",
      Rating: ""
    });
  }
 
  useEffect(() => {
    setForm({
      ApplicationUserId: localStorage.getItem("id"),
      ApplicationUserId:localStorage.getItem("name"),
      ProductId: queryParams.get("id"),
      Comment: text,
    
      Rating: selected
    });
    console.log(form);
    console.log(text);
  }, [text, selected]);
  function clearform() {
    setForm({});
  }
  return (
    <div>
      <br/>
      {form.ApplicationUserId ? (
  <>
    <Rating
      onClick={handleChange}
      ratingValue={selected}
      size={40}
      fillColor='orange'
      emptyColor='gray'
    />
    <div className="input-group">
      <input
        onChange={handleTextChange}
        type="text"
        placeholder="Write a review"
        value={text}
      />
      <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
    </div>
  </>
) : (

  <>
    <Rating
      onClick={handleChange}
      ratingValue={selected}
      size={40}
      fillColor='orange'
      emptyColor='gray'
    />
    <div className="input-group">
      <input
        onChange={handleTextChange}
        type="text"
        placeholder="Write a review"
        value={text}
      />
      <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
    </div>
  </>
)}

            <br />
      {review.map((item) => (
        <div key={item.id}>
          <div>
            <Rating
              initialValue={item.rating}
              readonly={true}
              size={20}
              fillColor='orange'
              emptyColor='gray'
            />
          </div>   
          
        
          <div id="main">
  <div id="tribute-info">
    {
      item.applicationUser && item.applicationUser.name
      ? <p>Name: {item.applicationUser.name}</p>
      : <p>Name: Empty</p>
    }
    <p>{item.comment}</p>
  </div>
</div>

        </div>
      ))}
    </div>
  )
}

export default ProductReview;