import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import api from "../../Api";
import Header from '../../../components/Header';
import { Divider } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material/Box';
import { toast } from "react-toastify";

function useQuery() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return searchParams;
  }
  
  function ProductCard() {
    const queryParams = useQuery();
    const [orderDeatail, setOrderDetail] = useState([]);
    const [productId, setproductId] = useState(0);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const orderId = queryParams.get('id');
    useEffect(()=>{
      const userRole = localStorage.getItem('role');
      if (userRole === 'Admin') {
        setIsAdmin(true);
      }
      getAll();
    },[]);
    
      function getAll(){
        const orderId = queryParams.get('id');
        api.get(`https://ottwebappnode.csdevhub.com/api/Orders/id`, {
    params: {
      orderId: orderId
    }
  }).then((d)=>{
    debugger
    setOrderDetail(d.data);
    setproductId(d.data.productId);
    console.log(d.data);
  
  })
    };
    function handleExchange() {
      debugger
      api.post(`https://ottwebappnode.csdevhub.com/api/shoppingCart/exchange`, {
        orderDetailId: orderId,
        newProductId: productId
      }, {
        params: {
          orderDetailId: orderId,
          newProductId: productId
        }
      }).then((response) => {
        toast.success(response.data);
      }).catch((error) => {
        toast.error(error.response.data);
      });
      ;
    }
  
    function handleRefund() {
      debugger
      const orderHeaderId = new URLSearchParams(window.location.search).get('orderHeaderId');
     api.post(`https://ottwebappnode.csdevhub.com/api/ShoppingCart/refund`, {}, {
      params: {
        orderId: orderHeaderId
      }
     })
        .then((response) => {
          toast.success(response);
          getAll();
        })
        .catch((error) => {
          toast.error(error.response.data);
        });
    }
    function handlerefundprocess() {
      debugger
      const orderHeaderId = new URLSearchParams(window.location.search).get('orderHeaderId');
       api.post(`https://ottwebappnode.csdevhub.com/api/ShoppingCart/processrefund`, {}, {
      params: {
        orderId: orderHeaderId
      }
     })
        .then((response) => {
          toast.success(response);
          getAll();
        })
        .catch((error) => {
          toast.error(error.response.data);
        });
    }
    return (
      <div>
        <Header />
        <div className="buynow_sectiond">
        <div className="buynow_container">
          <div className="left_buy">
            <h1>My Order</h1>
            <Divider />
            <div className="row">
              <div className="card1">
              <img src={`data:image/jpeg;base64,${orderDeatail.product && orderDeatail.product.imageUrl}`}
                      height="300" width="100"
                      className="card-img-top"
                      alt="Product"
                    />
              <div className="item_details">
             
              <h6>{orderDeatail.product && orderDeatail.product.title}</h6>
              <br/>
              <h3 className="different_price"> 
               Item Price:{orderDeatail.price}.00
              </h3>
              <br/>
              <h4 classname="different_price">
                Total Amount: {orderDeatail.orderHeader&& orderDeatail.orderHeader.orderTotal}.00
              </h4>
              <br/>
             
              <h3 className="unusual" style={{ color: 'green' }} >Order Status:{orderDeatail.orderHeader && orderDeatail.orderHeader.orderStatus}</h3>    <br/>
                <h3  className="unusual" style={{ color: 'red' }} >Payment Status:  {orderDeatail.orderHeader && orderDeatail.orderHeader.paymentStatus }</h3>    <br/>
                <p className="unusual">Payment Date: {new Date(orderDeatail.orderHeader&& orderDeatail.orderHeader.paymentDate).toLocaleDateString()}</p>
                <img
  
                     src="https://m.media-amazon.com/images/G/31/marketing/fba/fba-badge_18px-2x._CB485942108_.png"
                     alt="logo"
                   />
              <Divider />
              <br/>        
             <div class="row">
              <p  style={{ cursor: "pointer" }} onClick={handleExchange}> <b> Exchange </b>   </p>
              <span>|</span> 
              <p  style={{ cursor: "pointer" }} onClick={handleRefund} color='red'>  <b>  Cancel & refund</b></p> 
              {isAdmin && (    
                <p style={{ cursor: 'pointer' }} onClick={handlerefundprocess}>
                   <span>|</span> 
                <b> Process Refund</b> 
                </p>
                 )}
              </div>
              </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      
      </div>
    )
  }
  
  export default ProductCard