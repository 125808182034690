// import React from 'react';
// const ActionProvider = ({ createChatBotMessage, setState, children }) => {
//     return (<div>
//         {React.Children.map(children, (child) => { return React.cloneElement(child, { actions: {}, }); })}    </div>);
// };
// export default ActionProvider;


class ActionProvider {
  
  constructor(createChatBotMessage, setStateFunc) {
    const greet = () => {
      const greetingMessage = createChatBotMessage("Hi, friend.");
      updateChatbotState(greetingMessage);
    };

    const atm = () => {
      alert("atm method call")
      const message = createChatBotMessage(
        "An ATM card is a payment card issued by a financial institution, enabling users to perform transactions at ATMs and make purchases at POS terminals",
        {
          widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const Debitcard = () => {
      debugger;
      const message = createChatBotMessage(
        "Our return policy allows you to return or exchange most items within 30 days of delivery. Please refer to our website for detailed instructions and exceptions.",
        {
          widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const Creditcard = () => {
      debugger;
      const message = createChatBotMessage(
        "Yes, you can track your order by entering your order number or email address on our order tracking page.",
        {
          // widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const Accountopenningdetail = () => {
      debugger;
      const message = createChatBotMessage(
        "To cancel an order, please contact our customer support team with your order number. Cancellations are subject to our cancellation policy and may incur fees depending on the order status.",
        {
          // widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const Loandetail = () => {
      debugger;
      const message = createChatBotMessage(
        "To cancel an order, please contact our customer support team with your order number. Cancellations are subject to our cancellation policy and may incur fees depending on the order status",
        {
          // widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const Debitcarddigits = () => {
      debugger;
      const message = createChatBotMessage(
        "You must have noticed a 16-digit number on the front side of your Debit Card. This is known as the Debit Card number. This number is unique and primarily represents and identifies your Debit Card.",
        {
          // widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };
    const atmRedirect =()=>{
      alert("atmRedirect method is caled ")
    }
    const ApplyForCard = () => {
   
      const message = createChatBotMessage(
        "You can visit your bank's website and apply for a debit/ Credit Card in the personal banking/ retail banking section. Within Debt Cards as well, you can choose which category you wish to apply for.",
        {
          // widget: "javascriptLinks",
        }
      );
      updateChatbotState(message);
    };

    const updateChatbotState = (message) => {
      setStateFunc((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    };

    return {
      greet,
      Debitcard,
      atm,
      Creditcard,
      atmRedirect,
      Accountopenningdetail,
      Loandetail,
      ApplyForCard,
      atmRedirect,
      Debitcarddigits,
    };
  }
}

export default ActionProvider;
