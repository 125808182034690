import React, { useEffect, useMemo, useState } from 'react'
import { axios } from 'axios';
import { data } from 'jquery';
import Header from '../../components/Header';
import api from '../Api';
import Table from '../../components/Table1';
import { toast } from 'react-toastify';



function PendingOrders() {
    const [pendingOrders ,SetPendingOrders]=useState([])
  const [orderId, setorderid] = useState(null);
 

    useEffect(() => {
        getAllPendingOrders(); 
      }, []);

    function getAllPendingOrders()
    {
        debugger
        api.get("https://ottwebappnode.csdevhub.com/api/Orders/getpendingorders").then((d)=>{
            SetPendingOrders(d.data);

            console.log(d.data);

        })
    }
    function handleapprove(orderHeaderId) {
        debugger;
        api
          .post(`https://ottwebappnode.csdevhub.com/api/Orders/approvependingorders?orderHeaderId=${orderHeaderId}`)
          .then((response) => {
            toast.success(response.data);
            getAllPendingOrders();
          })
          .catch((error) => {
            toast.error(error.response.data);
          });
      }
    const columns = useMemo(
        () => [
          {
            Header: "OrderId",
            accessor: "id",
          },
          {
            Header: "orderHeaderId",
            accessor: "orderHeaderId",
          },
        
          {
            Header: "Product Title",
            accessor: "product.title",
          },
          {
            Header: "OrderStatus",
            accessor: "orderHeader.orderStatus",
          },
          {
            Header: "PaymentStatus",
            accessor: "orderHeader.paymentStatus",
          },
        //   {
        //     Header: "Image",
        //     accessor: "imageUrl",
        //     Cell: ({ cell: { value } }) => (
        //       <img
        //         className="card-img-top"
        //         src={`data:image/jpeg;base64,${value}`}
        //         height="100"
        //         alt="Product Image"
        //       />
        //     ),
        //   },
        
        {
            Header: "Action",
            Cell: ({ row: { original } }) => (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => handleapprove(original.orderHeaderId)}
                  >
                    Approve Orders
                  </button>
               
              </>
            ),
          },
        ],
        []
      );
    



  return (
    <div><Header/>
      <div className="table table-borderd text-center">
      <h1  className='text-center'>Pending Orders</h1>
        <Table columns={columns} paging={true} data={pendingOrders} />
      </div>
    </div>
  )
}

export default PendingOrders