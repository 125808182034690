import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const OverallSalesSummary = () => {
  const data = {
    year: 2024,
    month: 4,
    totalSales: 920
  };

  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Sales Data'
    },
    xAxis: {
      categories: [`${data.month}/${data.year}`]
    },
    yAxis: {
      title: {
        text: 'Total Sales'
      }
    },
    series: [{
      name: 'Sales',
      data: [data.totalSales]
    }]
  };

  return (
    <div>
      <div className='row'>
      <div className='col-4'>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      </div>
      <div className='col-3'>
        
      </div>
      </div>
    </div>
  );
};

export default OverallSalesSummary;