import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import api from "../Api";
import { Link, Routes, useNavigate } from "react-router-dom";
import ROUTES from "../../navigation/Router";
import { data } from "jquery";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

function ProceedBuy() {
  const [orderData, setOrderData] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [user, setUser] = useState(userDetail);
  const [orderTotal, setOrderTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getOrderDetail();
    getUserDetail();
    
  }, []);

  useEffect(() => {
    totalAmount();
    
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Use Stripe.js to create a token
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error(error);
    } else {
      // Send the token to your backend
      sendTokenToBackend(token.id);
       navigate("/Orderconfirm")
    }
  };

  const sendTokenToBackend = async (token) => {
    try {
      const response = await api.post('https://ottwebappnode.csdevhub.com/api/ShoppingCart/placeOrder', {
        token 
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (response.status === 200) {
        // Handle success, e.g., show a success message to the user
      } else {
        // Handle error response from the backend
      }
      // navigate(
      //   ROUTES.Orderconfirm.name 
         
      // );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const totalAmount = () => {
    let price = 0;
    orderData.map((item) => {
      price += item.product.price * item.count;
    });
    setOrderTotal(price);
  };

  // function getUserDetail() {
  //   api.get("https://ottwebappnode.csdevhub.com/api/ShoppingCart/applicationUser")
  //     .then((res) => {
  //       const userData = res.data;
  //       setUserDetail(userData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user details:", error);
  //     });
  // }

  function getOrderDetail() {
    api
      .get("https://ottwebappnode.csdevhub.com/api/ShoppingCart/proceedToBuy")
      .then((response) => {
        setOrderData(response.data);
        console.log(orderData);
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
      });
  }

  function getUserDetail() {
    api
      .get("https://ottwebappnode.csdevhub.com/api/ShoppingCart/applicationUser")
      .then((response) => {
        setUserDetail(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }

  return (
    <div>
      <Header />
     
  
        <br />
        <div className="backgroundWhiteBorder">
          <div className="container">
            <div className="card">
              <div className="card-header bg-dark text-light ml-0 row container">
                <div className="col-6">
                  <i className="fa fa-shopping-cart"></i> &nbsp; Order Summary
                </div>
                <div className="col-6 text-right">
                  <Link
                    to={"/Cart"}
                    className="btn btn-outline-info btn-sm"
                  >
                    Back to Cart
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="container rounded p-2">
                  <div className="row">
                    <div className="col-12 col-lg-6 pb-4">
                      <div className="row">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                          <span className="text-info">PickUp Details:</span>
                          {/* {userDetail.name} */}
                        </h4>
                      </div>

                      {userDetail.map((item) => (
                        <div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>Name</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.name}
                                name="name"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>Phone Number</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.phoneNumber}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>Street Address</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.streetAddress}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>City</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.city}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>State</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.state}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-3">
                              <label>Postal Code</label>
                            </div>
                            <div className="col-9">
                              <input
                                type="text"
                                value={item.postalCode}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="col-12 col-lg-5 offset-lg-1">
                      <h4 className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-info">Order Summary:</span>
                      </h4>
                      <ul className="list-group mb-3">
                        {orderData.map((item) => (
                          <li className="list-group-item d-flex justify-content-between">
                            <div>
                              <h6 className="my-0">{item.product.title}</h6>
                              <small className="text-muted">
                                Quantity: {item.count}
                              </small>
                            </div>
                            <span className="text-muted">
                              ${item.count * item.product.price}
                            </span>
                          </li>
                        ))}
                        <li className="list-group-item d-flex justify-content-between bg-light">
                          <small className="text-info">Total (USD)</small>
                          <strong className="text-info">{orderTotal}</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="card-footer">
                <div className="row">
                  <div className="col-12 col-md-8 pt-2">
                    {/* <p >estimate arrival date: @DateTime.Now.AddDays(7).ToShortDateString() - @DateTime.Now.AddDays(14).ToShortDateString()</p> */}
                  </div>
                  <div className="col-12 col-md-4">
                  <button
                      type="button"
                     
                      className="btn btn-success form-control"
                      data-toggle="modal" data-target="#exampleModal"
                    >
                       
                      place order
                    </button>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      
  
<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Stripe</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> 
      </div>
      <form onSubmit={handleSubmit}  data-dismiss="modal" >
        <div className="modal-body">
          <CardElement />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" disabled={!stripe} className="btn btn-primary">Pay</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
     </div>

 
  );

}

export default ProceedBuy;
