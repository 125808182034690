import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import store from './store';

import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const stripePromise = loadStripe('pk_test_51OgKdTDpVFasBAHfEBaKF2yPzA6yhPts1bTN2GROuCTU2r5pRwfeVQpxPERKDrPRJJCzmFEyvRLTDgUeE6hNoQVF00eDL1Y7wo');
root.render(
  <Elements stripe={stripePromise}>
  <Provider store={store}>      
        <App />      
    </Provider>,
  </Elements>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();