import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Divider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import api from "../../Api";
import { Link } from "react-router-dom";
import ROUTES from "../../../navigation/Router";

function MyOrder() {
  const [orderData, setOrderData] = useState([]); 
  useEffect(() => {
    fetchOrderData(); 
  }, []);

  function fetchOrderData() { 
    api
    
      .get("https://ottwebappnode.csdevhub.com/api/Orders")
      .then((response) => {
        setOrderData(response.data); 
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }

  return (
    <div>
      <Header />
      <div className="buynow_sectiond">
        <div className="buynow_container">
          <div className="left_buy">
            <h1>My Orders</h1>
            <span className="leftbuyprice">Price</span>
            <Divider />
            {orderData.map((item) => (
              <div key={item.id} className="item_containert"> 
            
             <img
                  src={`data:image/jpeg;base64,${item.product.imageUrl}`}
                  alt="imgitem"
                />
             
                
                <div className="item_details">
                  <h3>{item.product.title}</h3>
                  <h3 className="different_price"> 
                   ${item.product.price}.00
                  </h3>
                  <p className="unusual">Usually dispatched in 8 days.</p> {/* Corrected class name for unusual */}
                  <p>Eligible for FREE Shipping</p>
                  <img
                    src="https://m.media-amazon.com/images/G/31/marketing/fba/fba-badge_18px-2x._CB485942108_.png"
                    alt="logo"
                  />
                    <div className="add_remove_select">
                
                   <span>|</span> 
                   <Link
  to={`/ProductCard?id=${item.id}&orderHeaderId=${item.orderHeader.id}`}
  style={{ cursor: "pointer" }}
>
  Detail
</Link>
              <span>|</span> 

                 </div>
                  {/* <div className="add_remove_select">
                    <p style={{ cursor: "pointer" }}>Delete</p>
                    <span>|</span>
                    <p className="for_remove_media" style={{ cursor: "pointer" }}>Save for Later</p> 
                    <p className="for_remove_media" style={{ cursor: "pointer" }}>See More like this</p> 
                  </div> */}
                </div>
              </div>
            ))}
            <Divider />
            <div className="sub_item"></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default MyOrder;

