// // import React, { useState } from 'react'
// // import Header from '../../../components/Header'
// // import CanvasJSReact from '@canvasjs/react-charts';
// // //var CanvasJSReact = require('@canvasjs/react-charts');
 
// // var CanvasJS = CanvasJSReact.CanvasJS;
// // var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// // function AdminDashboard() {
// //   const [data,setData] = useState();
// //   const options = {
// //     title: {
// //       text: "Analytics Dashboard"
// //     },
// //     data: [{				
// //       type: "column",
     
// //       dataPoints: [
// //         { label: "January",  y: 0  },
// //         { label: "February", y: 400  },
// //         { label: "March", y: 26337  },
// //         { label: "April",  y: 0  },
// //         { label: "May",  y: 0 },
// //         { label: "kjh",  y: 11 },
// //         { label: "dssd",  y: 2 },
// //         { label: "sdsd",  y: 0 },
// //         { label: "sa  ",  y: 0 }
// //       ]
// //     }]
// //   }
// //   return (
// //     <div>
// //       <Header/>
// //       <CanvasJSChart options = {options}/></div>
// //   )
// // }
// // export default AdminDashboard
// import React, { useState, useEffect } from 'react';
// import Header from '../../../components/Header';
// import CanvasJSReact from '@canvasjs/react-charts';
// import axios from 'axios';

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// function AdminDashboard() {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get('https://ottwebappnode.csdevhub.com/api/Orders/sales-trend');
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const formatDataForChart = () => {
//     return data.map(item => ({
//       label: `${getMonthName(item.month)}, ${item.year}`,
//       y: item.totalSales
//     }));
//   };

//   const getMonthName = month => {
//     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//     return months[month - 1];
//   };

//   const options = {
//     title: {
//       text: "Analytics Dashboard"
//     },
//     data: [{				
//       type: "column",
//       dataPoints: formatDataForChart()
//     }]
//   };

//   return (
//     <div>
//       <Header />
//       <CanvasJSChart options={options} />
//     </div>
//   );
// }

// export default AdminDashboard;

import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import api from '../../Api';
import Footer from '../../home/footer/Footer';

const AdminDashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get('https://ottwebappnode.csdevhub.com/api/Orders/sales-trend');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatDataForChart = () => {
    return data.map(item => ({
      name: `${getMonthName(item.month)}, ${item.year}`,
      y: item.totalSales
    }));
  };

  const getMonthName = month => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[month - 1];
  };

  const options = {
    title: {
      text: "Analytics Dashboard"
    },
    xAxis: {
      type: 'category'
    },
    series: [{
      type: 'column',
      name: 'Total Sales',
      data: formatDataForChart()
    }]
  };

  const options1 = {
    title: {
      text: "Analytics Dashboard"
    },
    xAxis: {
      type: 'category'
    },
    series: [{
    type: 'line', 
    name: 'Total Sales',
    data: formatDataForChart(),
    annotations: [
      {
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: data.length - 1, // Adjust this value to target a specific data point
              y: data[data.length - 1]?.totalSales // Adjust this value to target a specific data point
            },
            text: 'Recent Sales'
          }
        ]
      }
    ]
  }]
  };

  return (
    <div>
      <Header />
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={options1}
      />
      <Footer/>
    </div>
  );
};

export default AdminDashboard;
