import React from 'react'
import Header from '../components/Header'

function Register() {
  return (
    <div>
        <Header/>
    </div>
  )
}

export default Register