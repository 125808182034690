// Assuming this code is in your index.js or a similar entry point

import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../src/features/counter/counterSlice';
import searchReducer from '../src/features/SearchData/searchSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    searchData: searchReducer,
  },
});

export default store;


// import { combineReducers, createStore } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import counterReducer from '../src/features/counter/counterSlice'
// import searchReducer from '../src/features/SearchData/searchSlice'

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const rootReducer = combineReducers({
//   searchReducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = createStore(persistedReducer);
// export const persistor = persistStore(store);