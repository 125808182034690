
import React, { useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../navigation/Router";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });



  // const [selectedValue, setSelectedValue] = useState('');

  // const handleChange1 = (event) => {
  //   setSelectedValue(event.target.value);
  //   console.log(selectedValue);
  // };

  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };


  const handleChange1 = (e) => {
    setValue(e.target.value);
    console.log(value);
  };

  const loginMessage = () => toast.success("Successfully logged in");
  const loginError = () => toast.error('Wrong username / password!', {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (value == "") {
      toast.error('Otp.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoading(false);
      return;
    }
    // Verify reCAPTCHA
    if (!recaptchaValue) {
      toast.error('Please verify reCAPTCHA.', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `https://ottwebappnode.csdevhub.com/api/Authenticate/login?num=${value}`,
        { ...form }
      );

      const { id } = response.data;
      setLoading(false);

      localStorage.setItem("id", id);
      navigate("/OtpLogin");
    } catch (error) {
      loginError();
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <section>
        <div className="sign_container">
          <div className="sign_header">
            <img
              src="https://logo-logos.com/2016/12/Amazon_logo.png"
              alt="signupimg"
            />
          </div>
          <div className="sign_form">
            <form method="POST">
              <h1>Sign-In</h1>

              <div className="form_data">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  id="email"
                />
              </div>
              <div className="form_data">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  id="password"
                  placeholder="At least 6 characters"
                />
              </div>


              {/* <div className="row">
                <div className="col-3">
                <label>
                  <input type="radio" />
                  Email
                </label>
                </div>
                <label>
                  <input type="checkbox" />
                  PhoneNumber
                </label>
              </div> */}


              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">OTP</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleChange1}
                >
                  <FormControlLabel value="1"  control={<Radio />} label="Phone Number" />
                  <FormControlLabel value="2"  control={<Radio />} label="Email" />
                </RadioGroup>
              </FormControl>

              {/* <button>Email</button>              
              <button>PhoneNumber</button> */}
              <div className="form_data">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  // sitekey="6LfA548pAAAAACM3J3niwdXEt_xGEtCeH6oob5rp"
                  onChange={(value) => setRecaptchaValue(value)}
                />
              </div>
              {loading ? (
                <p className="text-center">
                  <CircularProgress />
                </p>
              ) : (
                <button className="signin_btn" onClick={handleSubmit}>
                  Continue
                </button>
              )}
            </form>
            <ToastContainer />
          </div>
          <div className="create_accountinfo">
            <p>New to Amazon?</p>
            <button>
              <Link to={"/signup"}>Create your Account</Link>
            </button>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Login;

