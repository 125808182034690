import { useNavigate } from "react-router-dom";
import "./SearchResult.css";
import ROUTES from "../../../navigation/Router";
import { useEffect, useState } from "react";

export const SearchResult = ({ result }) => {
  const [selectedOption, setSelectedOption] = useState('');
//   useEffect(() => {
  
//     const timeoutId = setTimeout(() => {
//       window.location.reload();
//     }, 10000); 
//     return () => {
//       clearTimeout(timeoutId); 
//     };
// }, []);
 const options = [   
    { value: result.id, label: result.title },
    
  ];
  const navigate = useNavigate();
  return (
    <div
      className="search-result"
      onClick={(e) => {
        navigate("/detail"+ "?id=" + result.id);
      }}
    >
       {/* <select  >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
      {result.title}
      <div>  
      </div>
    </div>
  );
};
