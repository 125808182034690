import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { green } from "@mui/material/colors";
import success from "./images/success.jpg";
import './Orderconfirm.css'
import Footer from "../home/footer/Footer";
import ROUTES from "../../navigation/Router";
import { Link } from "react-router-dom";


function Orderconfirm() {
  
    const [pageRefreshed, setPageRefreshed] = useState(false);
  

    
    return  (
      // Display content when the page has been refreshed
      <div className="background">
        <Header />
        <div className="container row">
          <div className="col-12 text-center">
            <h1 className="txt__ text-center">Order Submitted Successfully</h1>
            <br />
            <img src={success} width="60%" alt="Success" />
          </div>
          <div className="col-12 text-center" style={{ color: green }}>
            <br />
            Thank you for your order! <br />
            We have received your order and we will send a follow-up email shortly!
            <br />
            <button className="btn btn-orange">
              <Link to={"/home"}>Continue To Shopping</Link>
            </button>
          </div>
        </div>
        <Footer />
      </div>
    ) ;
      
    

}

export default Orderconfirm;