import { useEffect } from "react";
import { SearchResult } from "../searchResult/SearchResult";
import "./SearchResultsList.css";

export const SearchResultsList = ({ results }) => {
  debugger

  return (
    <div className="results-list">
      {results.map((result, id) => {
        return <SearchResult result={result} key={id} />;
      })}
    </div>
  );
};
