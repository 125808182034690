import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.css";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import ROUTES from "../../../navigation/Router";
import { useDispatch, useSelector } from "react-redux";
import { savedata } from '../../../features/SearchData/searchSlice'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const SearchBar = ({ setResults }) => {
  const count = useSelector((state) => state.searchData.value)
  const dispatch = useDispatch()
  const [input, setInput] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(()=>{
  
  })
  const fetchData = (value) => {
    fetch("https://ottwebappnode.csdevhub.com/api/Product")
      .then((response) => response.json())
      .then((json) => {
        debugger
        const results = json.filter((user) => {
          return (
            value &&
            user &&
            user.title &&
            user.title.toLowerCase().includes(value)
          );
        });
        
        setResults(results);
        dispatch(savedata(results) || []);
        setInput(results);
      });
  };

  const handleChange = (value) => {
    fetchData(value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const searchInput = document.querySelector('.header__searchInput');
    searchInput.addEventListener('focus', () => setInputFocus(true));
    searchInput.addEventListener('blur', () => setInputFocus(false));

    return () => {
      searchInput.removeEventListener('focus', () => setInputFocus(true));
      searchInput.removeEventListener('blur', () => setInputFocus(false));
    };
  }, []);

  return (
    <div>
      <div className="header__searchForm">
        <div className="header__selectInput" onClick={toggleDropdown}>
          <div className={`dropdown ${inputFocus ? 'dropdown--open' : ''}`}>
            {dropdownOpen && (
              <ul className="dropdown__menu" style={{ backgroundColor: 'White' }}>
                <li class="border-white"  >
                <Link  class="text-dark" to="/allProducts">All</Link>
                  {/* <Link to={ROUTES.allProducts.name}>All</Link> */}
                </li>
                <li class="border-white">
                <Link  class="text-dark" to="/allMobile">Mobile</Link>
                </li>
                <li  class="border-white">
                <Link  class="text-dark" to="/allElectronic">Electronic</Link>
                  {/* <Link to={ROUTES.allElectronic.name}>Electronic</Link> */}
                </li>
                <li class="border-white">
                <Link class="text-dark" to="/allHomeKitchen">Home & Kitchen</Link>
                  {/* <Link to={ROUTES.allHomeKitchen.name}>Home & Kitchen</Link> */}
                </li>
              </ul>
            )}
          </div>
          <KeyboardArrowDownIcon className="header__selectInputIcon" />
        </div>
        <input
          className="header__searchInput"
          type="search"
          placeholder="Search Amazon.in"
          onChange={(e) => handleChange(e.target.value)}
          
        />

        <Link to={"/SearchResult"}>
          <SearchIcon  className="header__searchIcon" />
        </Link>
      </div>
    </div>
  );
};