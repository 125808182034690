import React from 'react'
import Header from '../../components/Header'

function About() {
  return (
    <div>
      <Header/>
    </div>
  )
}

export default About