import React from "react";
import "./LearningOptions.css";

const LearningOptions = (props) => {
  debugger
  const options = [
    { text: "What is the return policy for this product?", handler: props.actionProvider.Debitcard, id: 1 },
    { text: "Can I track my order?", handler: props.actionProvider.Creditcard, id: 2 },

    {
      text: "How do I cancel an order?",
      handler: props.actionProvider.Accountopenningdetail,
      id: 3,
    },
    { text: "How do I contact customer support?", handler: props.actionProvider.Loandetail, id: 4 },
    // { text: " card", handler: props.actionProvider.Creditcard, id: 6 },

  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default LearningOptions;
